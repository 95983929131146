<template>
  <div class="main">
    <div class="layout-top">
      <div class="flex-0">
          <div class="glo-flex-align-center">
             <div class="logo">
               <img src="@/assets/logo.png">
             </div>
             <div class="nav-record">
              <el-breadcrumb separator="/">
                <el-breadcrumb-item v-for="(item,index) in levelList" :key="index" :to="item.path">{{item.meta.title}}</el-breadcrumb-item>
              </el-breadcrumb>
             </div>
          </div>
          <div class="glo-flex-align-center">
            <div>
              <el-avatar :src="require('@/assets/userlogo.png')"></el-avatar>
            </div>
            <div class="admin-name">
               <el-popover placement="bottom" width="70" trigger="hover" :close-delay="100">
                  <div class="popover-list">
                    <div><el-button type="text" @click="dialogInfo">修改信息</el-button></div>
                    <div><el-button type="text" @click="dialog.password = true">修改密码</el-button></div>
                    <div><el-button type="text" class="exit-btn" @click="exitAccount">退出登录</el-button></div>
                  </div>
                  <div slot="reference">
                    <span>{{userAttr.userName || '加载中...'}}</span>
                    <i class="el-icon-caret-bottom"></i>
                  </div>
               </el-popover>
            </div>
          </div>
      </div>
    </div>
    <div class="layout-main">
       <div class="layout-menu">
          <el-menu
            :default-active="this.$route.path"
            router
          >
            <el-menu-item v-for="(item,index) in menuList" :key="index" :index="item.path">
              <template slot="title">
                <i :class="item.icon || 'el-icon-menu'"></i>
                <span v-text="item.name"></span>
              </template>
            </el-menu-item>
          </el-menu>
       </div>
       <div class="layout-inner">
          <router-view :userAttr='userAttr' />
       </div>
    </div>
    <!-- 对话框 -->
    <el-dialog
      title="编辑个人信息"
      :visible.sync="dialog.info"
      width="26%"
    >
      <el-form ref="formInfo" :rules="rulesInfo" :model="info_F" label-width="80px">
        <el-form-item label="姓名" prop="user_name">
          <el-input v-model="info_F.userName" maxlength="12" size="small"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="info_F.phone" maxlength="11" size="small"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="info_F.email" maxlength="32" size="small"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="submitInfo" size="small">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改密码"
      :visible.sync="dialog.password"
      width="26%"
      @closed="passwordClose">
      <el-form ref="formPwd" :rules="rulesPwd" :model="password_F" label-width="80px">
        <el-form-item label="旧密码" prop="oldPwd">
          <el-input type="password" maxlength="16" v-model="password_F.oldPwd" size="small" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input type="password" maxlength="16" v-model="password_F.newPwd" size="small" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPwd">
          <el-input type="password" maxlength="16" v-model="password_F.confirmPwd" size="small" show-password></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="submitPassword" size="small">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data(){
    // 自定义密码规则
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.password_F.confirmPwd !== '') {
          this.$refs.formPwd.validateField('confirmPwd');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.password_F.newPwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return{
      // userAttr: window.sessionStorage.getItem('USERINFO') ? JSON.parse(window.sessionStorage.getItem('USERINFO')) : {},
      userAttr: {},
      levelList:[],
      dialog:{
        info:false,
        password:false
      },
      menuList:[],
      info_F:{
        userName:'',
        phone:'',
        email:''
      },
      password_F:{
        oldPwd:'',
        newPwd:'',
        confirmPwd:''
      },
      rulesInfo:{
        userName: [
            { required: true, message: '请输入您的姓名', trigger: 'blur' },
            { min: 2, max: 12, message: '长度在 2 到 12 个字符', trigger: 'blur' }
          ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
      },
      rulesPwd:{
        oldPwd: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 4, max: 16, message: '长度在 4 到 16 个字符', trigger: 'blur' }
          ],
        newPwd: [
            { validator: validatePass, trigger: 'blur' },
            { min: 4, max: 16, message: '长度在 4 到 16 个字符', trigger: 'blur' }
        ],
        confirmPwd: [
          { validator: validatePass2, trigger: 'blur' },
          { min: 4, max: 16, message: '长度在 4 到 16 个字符', trigger: 'blur' }
        ],
      }
    }
  },
  methods:{
    dialogInfo(){
      this.dialog.info = true;
      this.info_F={...this.userAttr};
    },
    // 修改密码对话框关闭清空输入框
    passwordClose(){
      this.password_F = {
        oldPwd:'',
        newPwd:'',
        confirmPwd:''
      }
      setTimeout(()=>{
          this.$refs['formPwd'].clearValidate();
      },10)
    },
    // 修改信息
    submitInfo(){
      this.$refs['formInfo'].validate((valid) => {
        if (valid) {
          let data = {
            id:this.info_F.id,
            userName:this.info_F.userName,
            phone:this.info_F.phone,
            email:this.info_F.email,
          };
          this.$api.userUpdate(data).then( d => {
            console.log(d);
            this.$message({
                type: 'success',
                message: '修改成功，下次登录生效',
                duration:1500
            });
            this.dialog.info = false;
          })
        } else {
          return false;
        }
      });
    },
    // 修改密码
    submitPassword(){
      this.$refs['formPwd'].validate((valid) => {
        if (valid) {
          let data = {
            userId:this.userAttr.id,
            loginPassword:this.password_F.oldPwd,
            newPassword:this.password_F.newPwd,
            confirmPassword:this.password_F.confirmPwd
          }
          this.$api.resetPwd(data).then( d => {
            console.log(d);
            this.$message({
                type: 'success',
                message: '修改成功，请重新登录！',
                duration:1500
            });
            this.dialog.password = false;
            this.exitCore();
          })
        } else {
          return false;
        }
      });
    },
    exitCore(){
      let data = window.location.protocol+"//"+window.location.host ;
      return new Promise((resolve,rejuect) => {
          this.$api.logout(data).then( d=> {
            this.$message({
              type: 'success',
              message: '操作成功!',
              duration:1000
            });
            setTimeout(()=>{
              this.$router.push({path:'/login'});
              // window.location.href = d.msg;
            },500)
          })
          window.localStorage.clear();
      })
    },
    // 退出登录
    exitAccount(){
      this.$confirm('退出当前账户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.exitCore();
      }).catch(() => { });
    },
    // 获取路由层级
    getLevList(){
      if(this.$route.matched?.length){
        let arr = this.$route.matched.reduce( (acc,cur,index,oldArr) => {
          console.log(acc.meta.title,cur.meta.title);
          if(acc.meta.title === cur.meta.title){
            return [];
          }
          acc.path = '/';  //修复path为空，面包屑导航不能点击的bug
          return oldArr;
        })
        this.levelList = arr;
      }
    },
    // 获取用户信息
    getUser(){
      this.$api.getUserInfo().then ( d => {
          console.log(d);
          this.userAttr = { ...d }
      })
    },
    // 获取菜单信息
    getMenuList(){
      this.menuList = window.localStorage.getItem('menu-list') ? JSON.parse(window.localStorage.getItem('menu-list')) : [];
    }
  },
  mounted(){
      this.getLevList();
      this.getUser();
      this.getMenuList();
  },
  watch:{
    $route(to,from){
      this.getLevList();
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
  background-color: #f0f0f0;
  height: 100%;
  width: 100%;
  .layout-top{
    width: 100%;
    height: 80px;
    background-color: #ffffff;
    margin-bottom: 10px;
    .flex-0{
      display: flex;
      justify-content: space-between;
      height: 100%;
      padding: 0 50px;
      .logo{
        img{
          width: 50px; 
          height: 100%;
        }
      }
      .nav-record{
        margin-left: 30px;
      }
      .admin-name{
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
  .layout-main{
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
    .layout-menu{
      width: 200px;
      height: 100%;
      margin-right: 10px;
      background-color: #ffffff;
      flex: 0 0 200px;
      .el-menu{
        border-right:none;
        .el-menu-item.is-active{
          color: #409EFF;
          font-weight: bold;
        }
      }
    }
    .layout-inner{
      width: calc(100% - 210px);
      height: 100%;
    }
  }
}
.glo-flex-align-center{
  flex: 0 0 auto;
}
.exit-btn{
  color: rgb(253, 46, 46) !important;
  &:hover{
    color:red;
    font-weight: bold;
  }
}
</style>
